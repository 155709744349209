<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content=""
      :ok-disabled="isDisableBtn"
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row class="notiForm">
          <b-col cols="12">
            <!-- {{ message }} -->
            <b-form-group>
              <label for="noti_title">Notification title:</label>
              <input
                id="noti_title"
                v-model="notiTitle"
                name="notiTitle"
                min="0"
                data-vv-as="notiTitle"
                :class="{
                  input: true,
                  'is-danger': errors.has('notiTitle')
                }"
                v-validate="{ required: true }"
              />
              <span v-show="errors.has('notiTitle')" class="is-danger-text position-relative">{{
                errors.first('notiTitle')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- deposit_amount  -->
          <b-col cols="12">
            <!-- {{ message }} -->
            <b-form-group>
              <label for="amountInput">Notification content:</label>
              <b-form-input
                id="amountInput"
                v-model="message"
                name="message"
                min="0"
                trim
                data-vv-as="Amount"
                :class="{
                  input: true,
                  'is-danger': errors.has('message')
                }"
                v-validate="{ required: true }"
              />
              <span v-show="errors.has('message')" class="is-danger-text position-relative">{{
                errors.first('message')
              }}</span>
              <!-- <span class="is-danger-text position-relative"
              >Min value: 1.000₫</span
            > -->
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <!-- {{ message }} -->
            <b-form-group>
              <label for="notiUrl">Notification URL</label>
              <input
                id="notiUrl"
                v-model="notiUrl"
                name="notiUrl"
                min="0"
                data-vv-as="Amount"
                :class="{
                  input: true,
                  'is-danger': errors.has('notiUrl')
                }"
                v-validate="{ required: true }"
              />
              <span v-show="errors.has('notiUrl')" class="is-danger-text position-relative">{{
                errors.first('notiUrl')
              }}</span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <!-- {{ message }} -->
            <b-form-group>
              <label for="isPopup">Popup for user</label>
              <b-row class="mt-2">
                <b-col cols="1">
                  <b-form-radio v-model="isPopup" name="popup-radios" :value="true">Yes</b-form-radio>
                </b-col>
                <b-col cols="1">
                  <b-form-radio v-model="isPopup" name="popup-radios" :value="false">No</b-form-radio>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-row v-if="isPopup">
              <b-col cols="12">
                <b-form-group>
                  <label for="popupMessage">Popup message</label>
                  <input
                    id="popupMessage"
                    v-model="popupMessage"
                    name="popupMessage"
                    data-vv-as="label"
                    :class="{
                      input: true,
                      'is-danger': errors.has('popupMessage')
                    }"
                  />
                  <span v-show="errors.has('popupMessage')" class="is-danger-text position-relative">{{
                    errors.first('popupMessage')
                  }}</span>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <label for="buttonLabel">Button Label</label>
                  <input
                    id="buttonLabel"
                    v-model="buttonLabel"
                    name="buttonLabel"
                    data-vv-as="label"
                    :class="{
                      input: true,
                      'is-danger': errors.has('buttonLabel')
                    }"
                  />
                  <span v-show="errors.has('buttonLabel')" class="is-danger-text position-relative">{{
                    errors.first('buttonLabel')
                  }}</span>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <label for="buttonUrl">Button Url</label>
                  <input
                    id="buttonUrl"
                    v-model="buttonUrl"
                    name="buttonUrl"
                    data-vv-as="button url"
                    :class="{
                      input: true,
                      'is-danger': errors.has('buttonUrl')
                    }"
                  />
                  <span v-show="errors.has('buttonUrl')" class="is-danger-text position-relative">{{
                    errors.first('buttonUrl')
                  }}</span>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal
      v-model="modalSendList"
      size="lg"
      :title="modalTitleList"
      ok-title="Save"
      header-close-content=""
      :ok-disabled="!message.length"
      @ok="saveList"
      @cancel="closeList"
    >
      <b-container fluid>
        <b-row class="notiForm">
          <!-- deposit_amount  -->
          <b-col cols="12">
            <!-- {{ message }} -->
            <b-form-group>
              <label for="noti_title">Notification title:</label>
              <input
                id="noti_title"
                v-model="notiTitle"
                name="notiTitle"
                min="0"
                data-vv-as="notiTitle"
                :class="{
                  input: true,
                  'is-danger': errors.has('notiTitle')
                }"
                v-validate="{ required: true }"
              />
              <span v-show="errors.has('notiTitle')" class="is-danger-text position-relative">{{
                errors.first('notiTitle')
              }}</span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <!-- {{ message }} -->
            <b-form-group>
              <label for="amountInput">Notification content:</label>
              <b-form-input
                id="amountInput"
                v-model="message"
                name="message"
                min="0"
                trim
                data-vv-as="Amount"
                :class="{
                  input: true,
                  'is-danger': errors.has('message')
                }"
                v-validate="{ required: true }"
              />
              <span v-show="errors.has('message')" class="is-danger-text position-relative">{{
                errors.first('message')
              }}</span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <!-- {{ message }} -->
            <b-form-group>
              <label for="notiUrl">Notification URL</label>
              <input
                id="notiUrl"
                v-model="notiUrl"
                name="notiUrl"
                min="0"
                data-vv-as="Amount"
                :class="{
                  input: true,
                  'is-danger': errors.has('notiUrl')
                }"
                v-validate="{ required: true }"
              />
              <span v-show="errors.has('notiUrl')" class="is-danger-text position-relative">{{
                errors.first('notiUrl')
              }}</span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label for="phoneList">Additional phone list (Separate by comma): </label>
              <b-form-textarea
                id="phoneList"
                v-model="phoneListString"
                placeholder="Please add phone numbers to list"
              ></b-form-textarea>
              <span class="text-italic position-relative"
                >Added {{ validCount }} valid phone number{{ validCount > 1 ? 's' : '' }}</span
              >
            </b-form-group>
          </b-col>
          <b-col sm="12" md="8">
            <b-form-group>
              <label for="phoneList">Import phone list (.json, .xlsx)</label>
              <b-form-file
                v-model="phoneFile"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="4">
            <b-form-group>
              <label for="">Donwload example file</label>
              <div class="download__block d-flex">
                <b-button block variant="outline-success" class="download__btn" @click="downloadExample('json')">
                  <i class="far fa-arrow-alt-circle-down" style="margin-right: rem" />JSON</b-button
                >
                <b-button
                  block
                  class="download__btn mt-0 ml-2"
                  variant="outline-success"
                  @click="downloadExample('xlsx')"
                  ><i class="far fa-arrow-alt-circle-down" style="margin-right: rem" />EXCEL</b-button
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Send Notification</h4>
      </b-col>
      <!-- Filter UserName -->
      <b-col md="8" sm="12">
        <b-form-group id="input-name-filter" label="UserName" label-for="username-filter">
          <b-form-input
            id="username-filter"
            v-model="filter_username"
            type="text"
            placeholder="Search username ..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="4" sm="12" class="btnAdd">
        <b-button variant="success" @click="sendByList">Send By List</b-button>
        <b-button variant="primary" @click="sendAll">Send All</b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center orderTable"
    >
      <template v-slot:cell(total_balance)="item">
        {{ formatPrice(item.item.total_balance) }}
      </template>
      <template v-slot:cell(total_deposit)="item">
        {{ formatPrice(item.item.total_deposit) }}
      </template>
      <template v-slot:cell(total_paid)="item">
        {{ formatPrice(item.item.total_paid) }}
      </template>
      <template v-slot:cell(total_refund)="item">
        {{ formatPrice(item.item.total_refund) }}
      </template>
      <template v-slot:cell(actions)="item">
        <div @click="editItem(item.item)" style="cursor: pointer">
          <v-icon class="text-success">mdi-send</v-icon>
          <!-- <v-icon class="text-success">mdi-cash</v-icon> -->
        </div>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form inline id="input-per-page" style="margin-right: 20px; margin-bottom: 0">
          <label for="per-page" class="mr-sm-2">Rows per page: </label>
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            @change="fetchData"
          ></b-form-select>
        </b-form>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
import { exportRequest } from '@/utils/download.js';
import Swal from 'sweetalert2';
export default {
  name: 'send_notification',
  components: {},
  data() {
    return {
      isFilter: false,
      isBusy: false,
      filter_username: '',
      headers: [
        { text: 'Id', key: 'id' },
        { text: 'Name', key: 'name' },
        { text: 'Phone', key: 'phone' },
        { text: 'User Name', key: 'username' },
        // { text: "total_balance", key: "total_balance" },
        // { text: "total_deposit", key: "total_deposit" },
        // { text: "total_paid", key: "total_paid" },
        // { text: "total_refund", key: "total_refund" },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      modalTitle: '',
      modalTitleList: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        name: '',
        phone: '',
        username: '',
        total_balance: '',
        total_deposit: '',
        total_paid: '',
        total_refund: ''
      },
      message: '',
      dialog: false,
      editedIndex: -1,
      isSendAll: false,
      phoneListString: '',
      phoneFile: null,
      notiTitle: '',
      notiUrl: '',
      isPopup: false,
      popupMessage: '',
      buttonUrl: '',
      buttonLabel: '',
      modalSendList: false
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    modalSendList(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    },
    message: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.message = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return !this.message || this.errors.has('message');
    },
    isDisableBtnList() {
      return !this.message || this.errors.has('message');
    },
    validCount() {
      return this.validatePhoneList(this.phoneListString).length;
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Send Notification' }]);
  },
  methods: {
    downloadExample(format) {
      exportRequest(`/exports/phone-example?format=${format}`);
    },
    validatePhoneList(phoneString) {
      const phoneRegex = new RegExp(/\b(03|07|08|09|05)+([0-9]{8})(?=\D|\b)/g);
      return phoneString.split(',').filter((phone) => phone.trim().match(phoneRegex));
    },
    sendByList() {
      this.modalSendList = true;
      this.modalTitleList = 'Send Notification To List Of Users';
    },
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      ApiService.get('accounts', `?q=${this.filter_username}&limit=${limit}&offset=${offset}`)
        .then((resp) => {
          this.items = resp.data.data.accountList.map((ads) => {
            return {
              ...ads
            };
          });
          this.rows = resp.data.data.total;
          this.isBusy = false;
        })
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    editItem: function (item) {
      this.isSendAll = false;
      this.dialog = true;
      this.modalTitle = 'Send Notification';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
    },
    sendAll: function () {
      this.dialog = true;
      this.isSendAll = true;
      this.modalTitle = 'Send Notification All User';
    },
    saveList() {
      let formData = new FormData();
      formData.append('notiTitle', this.notiTitle.trim());
      formData.append('notiMessage', this.message.trim());
      formData.append('notiUrl', this.notiUrl.trim());
      formData.append('additionalPhone', this.phoneListString.trim());
      formData.append('phoneFile', this.phoneFile);
      axios({
        method: 'POST',
        url: `/send-notification-list`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        if (response && response.data && response.data.status) {
          const success = response.data.data.success;
          Swal.fire({
            icon: 'success',
            title: `Notification${success > 0 ? 's' : ''} sent to ${success} user${success > 0 ? 's' : ''}`,
            showConfirmButton: false,
            timer: 3000
          });
        } else
          Swal.fire({
            icon: 'error',
            title: `Notification sent to ${'xD'} user`,
            showConfirmButton: false,
            timer: 3000
          });
      });
      this.closeList();
    },
    closeList() {
      this.notiTitle = '';
      this.message = '';
      this.notiUrl = '';
      this.phoneListString = '';
      this.phoneFile = null;
    },
    close() {
      this.dialog = false;
      this.editedItem = {
        id: '',
        name: '',
        phone: '',
        username: '',
        total_balance: '',
        total_deposit: '',
        total_paid: '',
        total_refund: ''
      };
      this.message = '';
      this.editedIndex = -1;
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      // Get new brand/cate/vendor name for data-table
      let notifyData = {
        message: this.message || '',
        account_id: !this.isSendAll ? this.items[this.editedIndex].id : 0,
        notiTitle: this.notiTitle,
        notiUrl: this.notiUrl,
        is_popup: this.isPopup,
        custom_field: {
          popup_message: this.popupMessage,
          button_label: this.buttonLabel,
          button_url: this.buttonUrl
        }
      };
      if (this.editedIndex > -1 || this.isSendAll) {
        axios({
          method: 'POST',
          url: `send-notifications`,
          data: notifyData
        })
          .then((response) => {
            if (response.status === 200) {
              this.close();
              this.$bvToast.toast('Notification Manual added successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.$bvToast.toast(`Errors`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    }
  }
};
</script>

<style lang="scss">
.form-group {
  margin-bottom: 1rem !important;
}
.text-italic {
  font-style: italic;
}
.btnAdd {
  text-align: right;
  button + button {
    margin-left: 1rem;
  }
  margin-bottom: 1rem;
}
.download__btn {
  color: #0abb87;
  i {
    vertical-align: unset;
    font-size: 1rem;
  }
}
#amountInput,
.notiForm input {
  width: 100%;
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #9aabff;
    outline: 0;
  }
}
</style>
